import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { RegisterButton } from '../../UI'

const style = {
    wrapper: tw`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6`,
    threeGrid: tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6`,
    alterateGrid: tw`grid grid-cols-1 md:grid-cols-2 gap-6`,
    bigTitle: tw`antialiased leading-tight tracking-tighter text-3xl font-semibold items-start mb-2`,
    subTitle: tw`text-base font-normal items-start mb-8 `,
    textPurple: tw`text-tellow-purple`,
    text: tw`antialiased text-tellow-gray-600 text-xs leading-normal font-normal opacity-70 mb-0`,
    marginText: tw`mx-14`,
    content: tw`flex flex-col h-auto items-start px-6 py-5`,
    rowContent: tw`flex flex-row items-center mb-2`,
    littleTitle: tw`antialiased text-base font-medium tracking-tight mb-2`,
    backgroundCards: tw`rounded-xl bg-tellow-white shadow-md`,
    svg: tw`h-10 w-10 fill-none! text-tellow-purple mb-3 mr-4 rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
    altSvg: tw`h-14 w-14 fill-none! text-tellow-purple mb-3 mr-4 rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
}

const CenteredContent = styled.div`
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 768px) {
        padding-bottom: 1rem;
    }
`
const EndText = styled.p`
    font-style: italic;
    font-size: 13px;
    margin-top: 1rem;
    margin-bottom: 0rem;
    opacity: 0.5;
`

function TopHighlights({ data, alternate, threeCol, hasRegisterButton, textPurple, headerTextPurple }) {
    let Cards = null

    /**
     * TODO: Refactor this.
     */

    if (alternate) {
        Cards = data.points.map(({ Icon, title, body }) => (
            <div key={title}>
                <div css={style.rowContent}>
                    <div css={style.altSvg}>
                        <Icon />
                    </div>
                    <p css={style.littleTitle}>{title}</p>
                </div>
                <p css={[style.text, style.marginText]}>{body}</p>
            </div>
        ))
    } else {
        Cards = data.points.map(({ Icon, title, body }) => (
            <div key={title} css={style.backgroundCards}>
                <div css={style.content}>
                    <div css={style.svg}>
                        <Icon />
                    </div>
                    <p css={style.littleTitle}>{title}</p>
                    <p css={style.text}>{body}</p>
                </div>
            </div>
        ))
    }

    return (
        <div css={style.wrapper}>
            <h1 css={[style.bigTitle, headerTextPurple ? style.textPurple : null]}>{data.title}</h1>
            <p css={[style.subTitle, textPurple ? style.textPurple : null]}>{data.subtitle}</p>
            {!threeCol && <div css={alternate ? style.alterateGrid : style.grid}>{Cards}</div>}
            {threeCol && <div css={threeCol ? style.threeGrid : style.grid}>{Cards}</div>}

            {hasRegisterButton && (
                <CenteredContent>
                    <RegisterButton medium>Maak gratis facturen</RegisterButton>
                </CenteredContent>
            )}
            <EndText dangerouslySetInnerHTML={{ __html: data.endText }} />
        </div>
    )
}

export default TopHighlights
