import React from 'react'
import tw from 'twin.macro'

const Earth = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0001 32.4001C21.8192 32.4001 25.4819 30.883 28.1824 28.1824C30.883 25.4819 32.4001 21.8192 32.4001 18.0001C32.4001 14.181 30.883 10.5183 28.1824 7.81776C25.4819 5.11724 21.8192 3.6001 18.0001 3.6001C14.181 3.6001 10.5183 5.11724 7.81776 7.81776C5.11724 10.5183 3.6001 14.181 3.6001 18.0001C3.6001 21.8192 5.11724 25.4819 7.81776 28.1824C10.5183 30.883 14.181 32.4001 18.0001 32.4001ZM7.7977 14.4487C8.46602 12.5348 9.65851 10.8471 11.2393 9.5779C11.7217 10.3141 12.5533 10.8001 13.5001 10.8001C14.2162 10.8001 14.9029 11.0846 15.4093 11.5909C15.9156 12.0973 16.2001 12.784 16.2001 13.5001V14.4001C16.2001 15.3549 16.5794 16.2705 17.2545 16.9457C17.9296 17.6208 18.8453 18.0001 19.8001 18.0001C20.7549 18.0001 21.6705 17.6208 22.3457 16.9457C23.0208 16.2705 23.4001 15.3549 23.4001 14.4001C23.3998 13.5945 23.6698 12.812 24.1668 12.1779C24.6638 11.5439 25.3591 11.0948 26.1415 10.9027C27.8592 12.8677 28.8041 15.3901 28.8001 18.0001C28.8001 18.6121 28.7497 19.2151 28.6507 19.8001H27.0001C26.0453 19.8001 25.1296 20.1794 24.4545 20.8545C23.7794 21.5296 23.4001 22.4453 23.4001 23.4001V27.3547C21.759 28.3042 19.8961 28.8029 18.0001 28.8001V25.2001C18.0001 24.2453 17.6208 23.3296 16.9457 22.6545C16.2705 21.9794 15.3549 21.6001 14.4001 21.6001C13.4453 21.6001 12.5296 21.2208 11.8545 20.5457C11.1794 19.8705 10.8001 18.9549 10.8001 18.0001C10.8004 17.1487 10.499 16.3247 9.94929 15.6745C9.39962 15.0244 8.63729 14.59 7.7977 14.4487Z"
            fill="currentColor"
        />
    </svg>
)

export default Earth
