import React from 'react'
import tw from 'twin.macro'

const Book = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2001 8.64741C14.2376 7.6919 12.0829 7.19693 9.9001 7.20021C7.6411 7.20021 5.5027 7.72221 3.6001 8.64741V26.6474C5.56263 25.692 7.71734 25.197 9.9001 25.2002C12.9043 25.2002 15.6925 26.1182 18.0001 27.6932C20.387 26.0646 23.2105 25.1956 26.1001 25.2002C28.3591 25.2002 30.4975 25.7222 32.4001 26.6474V8.64741C30.4376 7.6919 28.2829 7.19693 26.1001 7.20021C23.8411 7.20021 21.7027 7.72221 19.8001 8.64741V21.6002C19.8001 22.0776 19.6105 22.5354 19.2729 22.873C18.9353 23.2106 18.4775 23.4002 18.0001 23.4002C17.5227 23.4002 17.0649 23.2106 16.7273 22.873C16.3897 22.5354 16.2001 22.0776 16.2001 21.6002V8.64741Z"
            fill="currentColor"
        />
    </svg>
)

export default Book
