import React from 'react'
import { PortalWithState } from 'react-portal'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Button } from '../../UI'
import { Container } from '../../../utils'

const styles = {
    container: tw`m-4`,
    imageContainer: tw`flex  justify-center`,
    content: tw`flex flex-col h-auto items-start lg:pt-10  md:px-0`,
    bigTitle: tw`antialiased leading-tight tracking-tighter text-4xl font-bold items-start mb-8`,
    popupTitle: tw`antialiased leading-tight tracking-tighter text-4xl font-bold text-tellow-white items-start mb-8`,
    text: tw`antialiased text-sm max-w-prose font-normal mb-10 opacity-80`,
    textPurple: tw`antialiased text-sm max-w-prose font-normal mb-5 opacity-80 text-tellow-purple`,
    img: tw`flex flex-col h-auto items-start lg:pt-8 px-4 md:inline mx-auto mt-4 mb-0 p-0 px-0`,
    wrapper: tw`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4`,
}

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2.5rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`

const ButtonContainerDesktop = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`
const ButtonContainerMobile = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }
`
const PopupConatinerBg = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    @media (max-width: 768px) {
        display: none;
    }
`
const PopupConatiner = styled.div`
    position: fixed;
    z-index: 1;
    left: 25%;
    top: 25%;
    width: 50%;
    height: auto;
    overflow: auto;
    background-color: white;
    border-radius: 20px;
    @media (max-width: 768px) {
        display: none;
    }
`
const PopupHeader = styled.div`
    box-shadow: 0px 8px 8px 0px #0000000d;
    background-color: #5950ec;
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: 768px) {
        display: none;
    }
`
const Image = styled.img`
    display: flex;
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
    margin-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    @media (min-width: 768px) {
        display: inline;
        height: 500px;
    }
    @media (min-width: 1024px) {
        padding-top: 2rem;
        height: 500px;
    }
`
const PlayStoreButton = styled(Button)`
    margin-bottom: 5px;
    width: 200px;
    padding: 9.5px 31.5px;
`

const AppStoreButton = styled(Button)`
    margin-bottom: 5px;
    width: 200px;
    padding: 11.8px 31.5px;
`

const TryTellow = ({ data }) => (
    <Container>
        <div css={styles.wrapper}>
            <MainContainer>
                <div style={styles.content}>
                    <p css={styles.textPurple}>{data.smallTitle}</p>
                    <h3 css={styles.bigTitle}>{data.title}</h3>
                    <p css={styles.text}>{data.text}</p>

                    <PortalWithState closeOnOutsideClick closeOnEsc>
                        {({ openPortal, closePortal, portal }) => (
                            <>
                                <ButtonContainerDesktop>
                                    <PlayStoreButton onClick={openPortal}>
                                        <img src={data.playStore} alt="Facturen" style={{ marginBottom: '0px' }} />
                                    </PlayStoreButton>

                                    <AppStoreButton onClick={openPortal}>
                                        <img src={data.appStore} alt="Facturen" style={{ marginBottom: '0px' }} />
                                    </AppStoreButton>
                                </ButtonContainerDesktop>
                                {portal(
                                    <PopupConatinerBg onClick={closePortal}>
                                        <PopupConatiner>
                                            <PopupHeader>
                                                <h3 css={styles.popupTitle} style={{ padding: '2% 0' }}>
                                                    {data.popupHeader}
                                                </h3>
                                            </PopupHeader>
                                            <div style={{ textAlign: 'center' }}>
                                                <p>{data.popupText}</p>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <img src={data.qrCode} alt="Facturen" style={{ width: '200px' }} />
                                            </div>
                                        </PopupConatiner>
                                    </PopupConatinerBg>
                                )}
                            </>
                        )}
                    </PortalWithState>
                    <ButtonContainerMobile>
                        <PlayStoreButton href="https://play.google.com/store/apps/details?id=nl.tellow.app&hl=nl">
                            <img src={data.playStore} alt="Facturen" style={{ marginBottom: '0px' }} />
                        </PlayStoreButton>

                        <AppStoreButton href="https://apps.apple.com/nl/app/tellow/id1187872148">
                            <img src={data.appStore} alt="Facturen" style={{ marginBottom: '0px' }} />
                        </AppStoreButton>
                    </ButtonContainerMobile>
                </div>
                <div css={styles.imageContainer}>
                    <Image src={data.Image} alt="Facturen" />
                </div>
            </MainContainer>
        </div>
    </Container>
)
export default TryTellow
