import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import TryTellow from '../../components/shared/landingPages/tryTellow'
import { Accordeon } from '../../components/UI'
import { Title } from '../../components/shared/landingPages/title'
import TimeClock from '../../images/hourregistraion/timeClock.js'
import Book from '../../images/offertelp/book.js'
import Earth from '../../images/invoicelp/earth.js'
import Bonnen from '../../images/invoicelp/bonnen.js'
import Credit from '../../images/icons/credit.js'
import header from '../../images/hourregistraion/Header-Image.png'
import tryTellowImage from '../../images/hourregistraion/TryTellowImage.png'
import playStoreImage from '../../images/hourregistraion/Playstore.png'
import appStoreImage from '../../images/hourregistraion/Appstore.png'
import qrCodeImage from '../../images/hourregistraion/qrCode.png'

const headerData = {
    title: `Urenregistratie`,
    text: `Houd gemakkelijk je uren bij met Tellow. Maak projecten aan, stel uurtarieven in en zet de gewerkte uren om naar facturen. `,
    headerImage: header,
    textCTA: `Gebruik Tellow gratis`,
}

const topHighlightData = {
    title: `Urenregistratie met Tellow`,

    points: [
        {
            Icon: TimeClock,
            title: `Beheer je projecten`,
            body: `Een duidelijk overzicht van al je projecten. Maak simpel nieuwe projecten en beheer deze in de app. Werk op project basis of klant basis.`,
        },
        {
            Icon: Book,
            title: `Omzetten naar factuur`,
            body: `Zet gemakkelijk je gewerkte uren om naar facturen. Zo kun je direct een factuur sturen naar je klant.`,
        },
        {
            Icon: Earth,
            title: `Rittenregistratie`,
            body: `Registreer ook je ritten in de app. Alles in een overzicht. `,
        },
        {
            Icon: Bonnen,
            title: `Inzicht in urencriterium  `,
            body: `Houd ook niet declareerbare uren bij. Zo heb je volledige inzicht in je urencriterium. `,
        },
    ],
}

const tryTellow = {
    title: `Simpel en snel vanaf je smartphone`,
    smallTitle: `Probeer Tellow`,
    text: `Boek je uren en houd volledige overzicht in de uitgebreide rapportages.`,
    Image: tryTellowImage,
    qrCode: qrCodeImage,
    popupHeader: `Tellow urenregistratie`,
    popupText: `Scan the code to download the Tellow app for free.`,
    playStore: playStoreImage,
    appStore: appStoreImage,
}

const featureData = [
    {
        Icon: Credit,
        title: `Facturen`,
        text: `Stuur onbeperkt facturen in je eigen huisstijl. Gebruik onze gedesignde templates voor een professionele look.`,
        link: ` Lees meer over facturen maken `,
        path: `facturen`,
    },
    {
        Icon: Credit,
        title: `Offertes`,
        text: `Stuur professionele offertes en zet deze direct om in facturen. Verhoog de acceptatieratio van je offertes door je eigen huisstijl en logo te gebruiken.`,
        link: `Lees meer over offertes maken `,
        path: `offertes`,
    },
]

const faq = [
    {
        question: `Urencriterium: Hoe zit dat precies?`,
        answer: `Het urencriterium is het minimale aantal uren dat je als ondernemer moet besteden aan je bedrijf om te profiteren van belastingvoordelen. 
        Als je voldoet aan deze eis kun je via aftrekposten je belastbare winst verlagen. Daardoor moet je minder inkomstenbelasting betalen en houd je onder de streep meer over.`,
    },
    {
        question: `Hoe hoog is het urencriterium?`,
        answer: `Het urencriterium 1225 uur. <br/><br/>
        Dit betekent dat je in een kalenderjaar minimaal 1225 uur voor je bedrijf gewerkt moet hebben. Het gaat hier om een kalenderjaar. Begin je met je business op 1 december dan ga je het waarschijnlijk niet halen om nog 1225 uur te schrijven. </br><br/>
        In de 1225 uur kunnen uren zitten die je voor een klant of project werkt. Maar ook uren die je in je onderneming stopt, bijvoorbeeld voor administratie, marketing of andere interne werkzaamheden.`,
    },
    {
        question: `Is het verplicht om mijn uren bij te houden als zzp’er?`,
        answer: `Nee, dit is niet verplicht. Wel handig. Je uren bijhouden heeft enkele voordelen. Door je uren bij te houden heb bijvoorbeeld meer inzicht in hoeveel tijd je besteed aan je verschillende werkzaamheden. Als uiteindelijk blijkt dat je, bijvoorbeeld, veel tijd kwijt bent aan je administratie. Kun je ervoor kiezen om te investeren in een boekhoudsoftware met boekhouder. Ook voorkom je dat je gewerkt uren niet juist declareert. Je doet ook meerdere fiscale voordelen op.`,
    },
    {
        question: `Hoe houd ik mijn uren bij in Excel?`,
        answer: `Het is natuurlijk ook mogelijk om je uren bij te houden in Excel. Dit heeft voordelen en nadelen. Excel is foutgevoelig en je kunt makkelijk ‘iets’ vergeten. Terwijl software die speciaal gemaakt is voor het bijhouden van je uren ervoor gemaakt is om deze fouten te voorkomen. Verder is urenregistratie via Tellow helemaal gratis.`,
    },
    {
        question: `Welke fiscale voordelen kan ik behalen met de urenregistratie?`,
        answer: `Naast enkele praktische voordelen, kun je ook fiscale voordelen opdoen met een urenregistratie Wanneer je als ondernemer in aanmerking komt voor het urencriterium, heb je recht op de zelfstandigenaftrek. Dit is een aftrekpost op je belasting.`,
    },
]

const Urenregistratie = () => (
    <Layout>
        <Meta
            path="/functies/urenregistratie"
            title="Doe je urenregistratie met Tellow"
            description=" Houd gemakkelijk en snel je uren bij via de urenregistratie functionaliteit van Tellow."
        />
        {/* Header component */}
        <Header data={headerData} purpleBackground bottomImage />

        {/* TopHighlights component */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* TryTellow component */}
        <RelativeContainer>
            <ComponentContainer>
                <TryTellow data={tryTellow} />
            </ComponentContainer>
        </RelativeContainer>

        {/* FeatureLinks component */}
        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* FAQ component */}
        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {faq.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Urenregistratie
