import React from 'react'
import tw from 'twin.macro'

const Bonnen = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6 3.6001C11.6452 3.6001 10.7295 3.97938 10.0544 4.65451C9.37928 5.32964 9 6.24532 9 7.2001V28.8001C9 29.7549 9.37928 30.6705 10.0544 31.3457C10.7295 32.0208 11.6452 32.4001 12.6 32.4001H23.4C24.3548 32.4001 25.2705 32.0208 25.9456 31.3457C26.6207 30.6705 27 29.7549 27 28.8001V7.2001C27 6.24532 26.6207 5.32964 25.9456 4.65451C25.2705 3.97938 24.3548 3.6001 23.4 3.6001H12.6ZM18 28.8001C18.4774 28.8001 18.9352 28.6105 19.2728 28.2729C19.6104 27.9353 19.8 27.4775 19.8 27.0001C19.8 26.5227 19.6104 26.0649 19.2728 25.7273C18.9352 25.3897 18.4774 25.2001 18 25.2001C17.5226 25.2001 17.0648 25.3897 16.7272 25.7273C16.3896 26.0649 16.2 26.5227 16.2 27.0001C16.2 27.4775 16.3896 27.9353 16.7272 28.2729C17.0648 28.6105 17.5226 28.8001 18 28.8001Z"
            fill="currentColor"
        />
    </svg>
)

export default Bonnen
